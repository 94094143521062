$item-color: white;
$item-background: #2196f3;
$item-border: 1px solid #1a6fb3;
$item-selected-color: white;
$item-selected-background: #ffc107;
$item-selected-border: 1px solid #ff9800;

$row-background-even: transparent;
$row-background-odd: rgba(0, 0, 0, 0.05);

$border-color: #bbb;
$border-width: 1px;
$thick-border-width: 2px;
$sidebar-color: #666; // #ffffff;
$sidebar-background-color: #f0f0f0; // #c52020;
$header-color: #666; // #ffffff;
$header-background-color: #f0f0f0; // #c52020;
$lower-header-color: #666; // #333333;
$lower-header-background-color: #f0f0f0;
$list-item-padding: 0 4px;
$weekend: rgba(250, 246, 225, 0.5);

.react-calendar-timeline {
  user-select: none;

  * {
    box-sizing: border-box;
  }

  .rct-outer {
    display: block;
    overflow: hidden;
    white-space: nowrap;
  }

  .rct-scroll {
    display: inline-block;
    white-space: normal; // was set to nowrap in .rct-outer
    vertical-align: top;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-touch-action: none;
    touch-action: none;
  }

  .rct-item {


    &:hover {
      z-index: 88;
    }

    .rct-item-content {
      position: sticky;
      position: -webkit-sticky;
      left: 0px;
      overflow: hidden;
      display: inline-block;
      border-radius: 2px;
      padding: 0 6px;
      height: 100%;
    }
  }

  .rct-header-container {
    z-index: 90;
    display: flex;
    overflow: hidden;

    &.header-sticky {
      position: sticky;
      position: -webkit-sticky;
    }
  }

  .rct-header {
    margin: 0;
    overflow-x: hidden;
    z-index: 90;

    .rct-top-header,
    .rct-bottom-header {
      position: relative;
    }

    .rct-label-group {
      padding: 0 5px;
      position: absolute;
      top: 0;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      border-left: $thick-border-width solid $border-color;
      color: $header-color;
      background: $header-background-color;
      border-bottom: $border-width solid $border-color;
      cursor: pointer;
      &.rct-has-right-sidebar {
        border-right: ($thick-border-width / 2) solid $border-color;
        border-left: ($thick-border-width / 2) solid $border-color;
      }

      & > span {
        position: sticky;
        left: 5px;
        right: 5px;
      }
    }

    .rct-label {
      position: absolute;
      // overflow: hidden;
      text-align: center;
      cursor: pointer;
      border-left: $border-width solid $border-color;
      color: $lower-header-color;
      background: $lower-header-background-color;
      border-bottom: $border-width solid $border-color;
      cursor: pointer;

      &.rct-label-only {
        color: $header-color;
        background: $header-background-color;
      }

      &.rct-first-of-type {
        border-left: $thick-border-width solid $border-color;
      }
    }
  }

  .rct-sidebar-header {
    margin: 0;
    color: $sidebar-color;
    background: $sidebar-background-color;
    border-right: $border-width solid $border-color;
    box-sizing: border-box;
    border-bottom: $border-width solid $border-color;
    overflow: hidden;

    &.rct-sidebar-right {
      border-right: 0;
      border-left: $border-width solid $border-color;
    }
  }

  .rct-sidebar {
    overflow: hidden;
    white-space: normal; // was set to nowrap in .rct-outer
    display: inline-block;
    vertical-align: top;
    position: relative;
    box-sizing: border-box;
    border-right: $border-width solid $border-color;

    &.rct-sidebar-right {
      border-right: 0;
      border-left: $border-width solid $border-color;
    }

    .rct-sidebar-row {
      padding: $list-item-padding;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-sizing: content-box;
      margin: 0;
      border-bottom: $border-width solid $border-color;

      &.rct-sidebar-row-odd {
        background: $row-background-odd;
      }
      &.rct-sidebar-row-even {
        background: $row-background-even;
      }
    }
  }

  // TODO: rename or remove once we make breaking change to rename vertical lines
  // to columns
  .rct-vertical-lines {
    .rct-vl {
      position: absolute;
      border-left: 1px solid $border-color;
      z-index: 30;
      &.rct-vl-first {
        border-left-width: 2px;
      }
      &.rct-day-6,
      &.rct-day-0 {
        background: $weekend;
      }
    }
  }

  .rct-horizontal-lines {
    .rct-hl-even,
    .rct-hl-odd {
      border-bottom: $border-width solid $border-color;
      box-sizing: content-box;
      z-index: 40;
    }
    .rct-hl-odd {
      background: $row-background-odd;
    }
    .rct-hl-even {
      background: $row-background-even;
    }
  }

  .rct-cursor-line {
    position: absolute;
    width: 2px;
    background: $item-background;
    z-index: 51;
  }

  .rct-infolabel {
    position: fixed;
    left: 100px;
    bottom: 50px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    font-size: 20px;
    border-radius: 5px;
    z-index: 85;
  }
}
