.start-marker, .stop-marker {
  width: 80px;
  height: 40px;
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  user-select: none;
  border-radius: 18px;
  justify-content: center;
  color: #fafafa;
}
.stop-marker {
  background-color: #f44336;
}
.start-marker {
  background-color: #2ca25f;
}

    