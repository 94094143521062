body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#search-input>div>div:first-of-type:before {
  border: 0 !important;
}

#search-input>div>div:first-of-type:after {
  border: 0 !important;
}

.react-grid-Cell {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
