.rbc-events-container {
    display: grid;
    grid-template-rows: repeat(24, 40px);
  }
  
  .rbc-day-slot .rbc-event {
    position: static;
  }
  
  .rbc-day-slot .rbc-event--global {
    background-color: #fbab18;
  }
  
  .rbc-day-slot .rbc-event--regional {
    background-color: #6ebe4a;
  }
  .rbc-event.rbc-selected {
    background-color: #ff0000;
  }