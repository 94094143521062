.bus-stop {
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  vertical-align: middle;
}

.bus-stop-sm {
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  vertical-align: middle;
  font-size: 12px;
}